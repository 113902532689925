<template>
  <div class="columns">
    <div class="column is-full">
      <el-card
          class="box-card"
          shadow="never"
      >
        <div
            slot="header"
            class="clearfix"
        >
          <span><i class="fa fa fa-book"> 主题 / 发布主题</i></span>
        </div>
        <div>
          <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm"
          >
            <el-form-item prop="title">
              <el-input
                  v-model="ruleForm.title"
                  placeholder="输入主题名称"
              />
            </el-form-item>

            <!--Markdown-->
            <div id="vditor"/>

<!--            <el-row>
              &lt;!&ndash; accept=".mp4,.png",这个填什么就会在文件夹中显示什么，如果只有.mp4的话，打开文件夹是没有其他文件的。:auto-upload="false"这个一定要有，否则会重复请求的 &ndash;&gt;
              <el-col :span="12">
                <el-upload class="upload-demo" accept=".mp4,.png" :on-change="handleChange" :on-success="handleAvatarSuccess"
                           drag action="#" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
              </el-col>
              <el-col :span="12">
                <video id="playVideos" width="320" height="240" controls muted>
                  <source id="playVideosss" src="" type="video/mp4">
                  &lt;!&ndash; <source src="movie.ogg" type="video/ogg"> &ndash;&gt;
                  您的浏览器不支持 video 标签。
                </video>
              </el-col>
            </el-row>

            <el-row>
              <el-progress style="width: 360px;" :text-inside="true" :stroke-width="26" :percentage="uploadprogress">
              </el-progress>
            </el-row>-->

            <b-taginput
                v-model="ruleForm.tags"
                class="my-3"
                maxlength="15"
                maxtags="3"
                ellipsis
                placeholder="请输入主题标签，限制为 15 个字符和 3 个标签"
            />

            <el-form-item>
              <el-button
                  type="primary"
                  @click="submitForm('ruleForm')"
              >立即创建
              </el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {post} from '@/api/post'
import Vditor from 'vditor'
import 'vditor/dist/index.css'
import SparkMD5 from 'spark-md5'
import axios from "axios";
// import {VDITOR_VERSION} from "vditor/src/ts/constants";

export default {
  name: 'TopicPost',

  data() {
    return {
      contentEditor: {},
      ruleForm: {
        title: '', // 标题
        tags: [], // 标签
        content: '' // 内容
      },
      rules: {
        title: [
          {required: true, message: '请输入话题名称', trigger: 'blur'},
          {
            min: 1,
            max: 25,
            message: '长度在 1 到 25 个字符',
            trigger: 'blur'
          }
        ]
      },
      chunkSize: 1024 * 1024 * 5,
      uploadprogress: 0
    }
  },
  mounted() {
    this.contentEditor = new Vditor('vditor', {
      height: window.innerHeight / 2.2,
      // height: "auto",
      placeholder: '此处为话题内容……',
      theme: 'classic',
      counter: {
        enable: true,
        type: 'markdown'
      },
      preview: {
        delay: 0,
        hljs: {
          style: 'monokai',
          lineNumber: true
        }
      },
      tab: '\t',
      /*hint: {
        delay: 200,
        emoji: {
          "+1": "👍",
          "-1": "👎",
          "confused": "😕",
          "eyes": "👀️",
          "heart": "❤️",
          "rocket": "🚀️",
          "smile": "😄",
          "tada": "🎉️",
        },
        // emojiPath: `https://unpkg.com/vditor@${VDITOR_VERSION}/dist/images/emoji`,
        extend: [],
        parse: true,
      },*/
      typewriterMode: true,
      toolbar: [
        "emoji",
        "headings",
        "bold",
        "italic",
        "strike",
        "|",
        "list",
        "ordered-list",
        "check",
        "outdent",
        "indent",
        "|",
        "quote",
        "line",
        "code",
        "inline-code",
        // "insert-before",
        // "insert-after",
        "|",
        "upload",
        "record",
        "table",
        "|",
        "undo",
        "redo",
        // "|",
        // "fullscreen",
        // "edit-mode",
        // {
        //   name: "more",
        //   toolbar: [
        //     "both",
        //     "code-theme",
        //     "content-theme",
        //     "export",
        //     "outline",
        //     "preview",
        //     "devtools",
        //     "info",
        //     "help",
        //   ],
        // },
      ],
      toolbarConfig: {
        pin: false,
        hide:false
      },
      cache: {
        enable: false
      },
      mode: 'ir',

      //普通的图片或文件上传
      upload: {
        accept: 'image/jpg, image/jpeg, image/png, image/gif, .mp4, .zip, .wav, .mov',//规定上传的图片格式
        url: process.env.VUE_APP_SERVER_URL + "/post/uploadMdPic",//图片或文件上传
        multiple: false,
        fieldName: 'file',
        max: 1000 * 1024 * 1024,//上传图片或视频的大小
        timeout: 30000,
        // extraData: { 'access_token': this.token }, //为 FormData 添加额外的参数
        // linkToImgUrl: "/api/admin/uploadFile?type=99",外链图片本地化
        filename(name) {
          return name.replace(/[^(a-zA-Z0-9\u4e00-\u9fa5\.)]/g, "")
              .replace(/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g, "")
              .replace("/\\s/g", "");
        },
        format(files, responseText) {
          // let imageResult = JSON.parse(responseText)
          let code = JSON.parse(responseText)
          let msg = JSON.parse(responseText)
          let data = JSON.parse(responseText)
          let filName = data.url
          //上传文件请求状态
          if (data.code === 200) {
            let lastTipNum = filName.substr(filName.lastIndexOf('/', filName.lastIndexOf('/') - 1) + 1);
            let index = lastTipNum.lastIndexOf("\/");
            self.imgNameStr = decodeURI(lastTipNum.substring(index + 1, lastTipNum.length));
            // let responseData = self.gb.imgPath(data.result.image_url)
            let responseData = data.url
            let succ = {}
            succ[self.imgNameStr] = responseData
            //文件回显
            return JSON.stringify({
              msg,
              code,
              data: {
                errFiles: [],
                succMap: succ
              }
            })
          }
        },
        error(msg) {
          console.log(msg + "上传失败了")
        },
      }
    })
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let contentStr=''
          if (
              this.contentEditor.getValue().length === 1 ||
              this.contentEditor.getValue() == null ||
              this.contentEditor.getValue() === ''
          ) {
            // contentStr =this.contentEditor.getValue() +'123'
            alert('话题内容不可为空')
            return false
          }
          if (this.ruleForm.tags == null || this.ruleForm.tags.length === 0) {
            alert('标签不可以为空')
            return false
          }
          // this.ruleForm.content = contentStr
          this.ruleForm.content = this.contentEditor.getValue()
          post(this.ruleForm).then((response) => {
            const {data} = response
            setTimeout(() => {
              this.$router.push({
                name: 'post-detail',
                params: {id: data.id}
              })
            }, 800)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.contentEditor.setValue('')
      this.ruleForm.tags = ''
    }
    /*handleAvatarSuccess(resp) {
      console.log(resp)
    },
    // dianji() {
    //   console.log('vue和element ui样式测试')
    //   console.log(this.message)
    // },
    async handleChange(file, fileList) {
      // debugger
      this.uploadprogress = 0
      // 每个选集的 md5，Math.ceil向上取整，不会的话就百度
      let chunks = Math.ceil(file.size / this.chunkSize);
      const spark = new SparkMD5.ArrayBuffer();
      for (let i = 0, start = 0; i < chunks; i++, start += this.chunkSize) {
        // 文件分割是注意是file.raw.slice(start, end)，这里是element ui中特有的。注意是特有的
        let end = Math.min(file.size, start + this.chunkSize);
        // console.log('read chunk nr', i + 1, 'of', chunks, start, end);
        let data = await this.readFile(file.raw.slice(start, end));
        spark.append(data);
      }
      // 这里spark.end()是获取视频对应的md5文件名
      const md5 = spark.end() + ".mp4";
      // 上传选集，分片循环上传。file.slice(start, end)
      for (let i = 0, start = 0; i < chunks; i++, start += this.chunkSize) {
        let end = Math.min(file.size, start + this.chunkSize);
        let json = await this.upload(file.raw.slice(start, end), (i + 1), chunks, md5);
        // 这里是计算百分比，因为这里循环是从0开始的，所以这里需要加1
        this.uploadprogress = ((i + 1) * 100.0 / chunks).toFixed(2)
      }
      await this.finish(chunks, md5);
      // let vdo = document.getElementById("playVideos")
      // 这个是视频播放的地址路径，这里就可以根据后端的提示进行返回对应的路径了，这里需要看静态资源映射的方法。
      // vdo.src = 'http://localhost:8080/play/2_1.mp4';
      // vdo.play();
    },
    // 将文件上传成功时的回调
    readFile(file) {
      return new Promise((resolve, reject) => {
        // 文件读取
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },

    async upload(data, i, chunks, part) {
      const form = new FormData();
      // 这里的data是文件
      form.append("data", data);
      form.append("i", i);
      form.append("chunks", chunks);
      // 这里的url是文件名
      form.append("filename", part);
      await axios.post(process.env.VUE_APP_SERVER_URL + "/upload/uploadVideo", form)
    },
    // 合并分块上传的视频
    async finish(chunks, part) {
      const form = new FormData();
      form.append("chunks", chunks);
      form.append("filename", part);
      const resp = await axios.post(process.env.VUE_APP_SERVER_URL + "/upload/finish", form)
    }*/
  }
}
</script>

<style>
</style>
